// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  youtube: 'AIzaSyAlZCbHHrQZ1972pDlruu3cxJYePOnKuOo',
  ampacheKey: "dbdd1bf639c7e95e5cb540cdb663249a",
  apiKey: 'AIzaSyByVPIePX2IsVQ--BKTnz4FqPVtbk4-VNY',
  channel: 'UCG3a2dMOrPsRlz6838Hdb6Q',
  manish: 'PL8XfiBYPX2FQW3PW3v-bEvHWtYlObPVVn',
  StudioWyzz: 'PL8XfiBYPX2FTxGOXmLqu1HrYJ7LQPl1RC',
  Ffion: 'PL8XfiBYPX2FS2JTj0jzs3rqHHndkNjVtZ',
  Harri: 'PL8XfiBYPX2FTAsTLyaXJkNKvFRiNGLPy7',

  firebase: {
    apiKey: "AIzaSyAGPVgWDHqvYBksBI696-P5Q8dCWDx0tLk",
    authDomain: "hq-recording.firebaseapp.com",
    projectId: "hq-recording",
    storageBucket: "hq-recording.appspot.com",
    messagingSenderId: "225309282411",
    appId: "1:225309282411:web:d0832113146967f301212e",
    measurementId: "G-Y7BMG3G4L3"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
