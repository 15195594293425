import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { VideosPage } from './videos/videos.page';
import { GlobalVariable } from './globals';
import { FcmService } from './services/fcm.service';
import { Platform } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
// import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Media } from '@ionic-native/media/ngx';
import { AlbumPage } from 'src/app/album/album.page';
import { FormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { Share } from '@capacitor/share';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MusicControls } from '@awesome-cordova-plugins/music-controls/ngx';
import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    ReactiveFormsModule,

    IonicModule.forRoot(),
    CoreModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [
    MusicControls,
    File,
    StreamingMedia,
    MediaCapture,
    Media,
    GlobalVariable,
    VideosPage,
    FcmService,
    Platform,
    AlbumPage,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
