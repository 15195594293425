import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalVariable } from '../globals';
import { Capacitor, Plugins } from '@capacitor/core'; // Native version
import { Device } from '@capacitor/device';
import Hls from "hls.js";

@Component({
  selector: 'app-vid-modal',
  templateUrl: './vid-modal.page.html',
  styleUrls: ['./vid-modal.page.scss'],
})

export class VidModalPage implements OnInit, AfterViewInit {

  currentYear = new Date().getFullYear();
  title;
  constructor(private navParams: NavParams, private modalController: ModalController, public sanitizer: DomSanitizer, public globals: GlobalVariable) { }

  id: any;
  transform() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.globals.vid);
  }

  @Input() link: string;
  player: any;
  videoId: string = "";
  stopped: boolean = true;
  videoUrl: string = "";
  video: any;


  ngOnInit() {
    const tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);

    // this.initializeYoutubePlayerPluginWeb();

    // Device.getInfo().then(info => {
    //   if (info.platform === 'web') {
    //     this.initializeYoutubePlayerPluginWeb();
    //   } else {
    //     this.initializeYoutubePlayerPluginNative();
    //   }
    // })
    // YoutubePlayer.playVideo("youtube-player");
    // console.log(this.video)
    this.videoUrl = "https://www.youtube.com/embed/" + this.video.snippet.resourceId.videoId + "&origin=https://settrippn.com";
    let videos = "https://www.settrippn.com/ye/ye.mp4"
    let vidLink = this.getSanitizedURL();

    // if (Hls.isSupported()) {
    //   var video = document.getElementById('video');
    //   var hls = new Hls();
    //   hls.on(Hls.Events.MEDIA_ATTACHED, function () {
    //     console.log('video and hls.js are now bound together !');
    //   });
    //   hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
    //     console.log(
    //       'manifest loaded, found ' + data.levels.length + ' quality level'
    //     );
    //   });
    //   //hls.loadSource('https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8');
    //   console.log();
    //   hls.loadSource(videos);
    //   // bind them together
    //   hls.attachMedia(<HTMLMediaElement>video);
    // }
    // console.log("debug globals:", this.globals.vid);
    // console.log(`${this.title} `);
  }
  ngAfterViewInit() {
    if (Capacitor.getPlatform() === 'web') {
      // this.initializeYoutubePlayerPluginWeb();
    } else { // Native
      // this.initializeYoutubePlayerPluginNative();
    }
  }

  ionViewWillEnter() {
    // Access component props in the constructor or ionViewWillEnter lifecycle hook
    this.video = this.navParams.get('video');
    console.log(this.video)

  }


  async initializeYoutubePlayerPluginWeb() {
    // console.log('HomePage::initializeYoutubePlayerPluginWeb() | method called');
    // const options: IPlayerOptions = {
    //   playerId: 'youtube-player',
    //   playerSize: {width: 640, height: 360},
    //   playerVars: {
    //     autoplay: 1,
    //     rel: 0,
    //     color: 'white',
    //     showinfo: 0,
    //   },
    //   videoId: this.video.snippet.resourceId.videoId,
    //   fullscreen: false,
    //   debug: false
    // };
    // const result = await YoutubePlayer.initialize(options);
    // console.log('playerReady', result);

    // (result as any).player.addEventListener('onPlaybackQualityChange', (event) => {
    //   console.log('playback quality is', event);
    // });

    // (result as any).player.addEventListener('onStateChange', (event) => {
    //   console.log('state is', event);
    // });

    // const options1: IPlayerOptions = {playerId: 'youtube-player1', playerSize: {width: 640, height: 360}, videoId: 'M1F81V-NhP0'};
    // const result1 = await YoutubePlayer.initialize(options1);
    // console.log('playerReady', result1);

    // YoutubePlayer.addEventListener(options1.playerId, 'onStateChange', (event: PlayerEvent) => {
    //   console.log(`Player ${options1.playerId} state is`, event);
    // });
  }

  async destroyYoutubePlayerPluginWeb() {
    // console.log('HomePage::destroyYoutubePlayerPluginWeb() | method called');
    // const result = await YoutubePlayer.destroy('youtube-player');
    // console.log('destroyYoutubePlayer', result);
  }

  async getPlayersEventsStatePluginWeb() {
    // console.log('HomePage::getPlayersEventsStatePluginWeb() | method called');
    // const result = await YoutubePlayer.getAllPlayersEventsState();
    // console.log('allPlayersEventsState', result);
  }

  async initializeYoutubePlayerPluginNative() {

    // const options: IPlayerOptions = {playerSize: {width: 640, height: 360}, videoId: 'tDW2C6rcH6M', fullscreen: true};
    // const playerReady = await YoutubePlayer.initialize(options);
  }



  async dismissModal() {
    this.modalController.dismiss();
  }

  getSanitizedURL() {

    return this.sanitizer.bypassSecurityTrustUrl('https://www.youtube.com/embed/' + this.video.snippet.resourceId.videoId);
  }




}
