import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Album, MusicPlayer, Track, likedList } from '../core/musicPlayer.services';

import { GlobalVariable } from '../globals';
import { ModalController } from '@ionic/angular';
import { PlayerPage } from '../player/player.page';
import { AuthService } from '../services/auth.service';
import { CommentsModalPage } from '../comments-modal/comments-modal.page';

@Component({
  selector: 'app-album',
  templateUrl: './album.page.html',
  styleUrls: ['./album.page.scss']
})
export class AlbumPage implements OnInit {
  album: Album = null;
  discog: any;
  selectedTrack = null;
  selectedArt = '';
  playlist = null;
  sameAlbum = false;
  year: number;
  userId: string;

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    public musicPlayer: MusicPlayer,
    public globals: GlobalVariable,
    private auth: AuthService
  ) {
    this.userId = this.auth.getId();
  }

  isUserLiked(song: any): boolean {
    return song.likes?.includes(this.userId);
  }

  async openComments(track, i) {
    const modal = await this.modalCtrl.create({
      component: CommentsModalPage,
      breakpoints: [0, 0.4, 0.8],
      initialBreakpoint: 0.4,
      componentProps: {
        track: track
      }
    });
    await modal.present();
  }

  // Function to toggle favorite status
  toggleFavorite(song: any): void {
    if (this.isUserLiked(song)) {
      this.removeSongFromFav(song);
    } else {
      this.addSongToFav(song);
    }
  }

  areAllSongsFavorited(): boolean {
    return this.album.tracks.every((song) => song.likes?.includes(this.userId));
  }

  // Function to toggle the favorite status for all songs in the album
  toggleAllSongsFavorite(): void {
    if (this.areAllSongsFavorited()) {
      this.album.tracks.forEach((song) => this.removeSongFromFav(song));
    } else {
      this.album.tracks.forEach((song) => this.addSongToFav(song));
    }
  }

  // Function to add song to favorites
  async addSongToFav(song: any): Promise<void> {
    const foundSongIndex = this.album.tracks.findIndex((track) => track.title === song.title);

    if (foundSongIndex !== -1) {
      // Step 2: Update the song data with the new information fetched from Firebase
      const ref = `discography/${this.toCamelCase(this.album.name)}`;
      const fbAlbum = await this.musicPlayer.getDocument(ref);

      // Assuming fbAlbum.data.tracks contains the updated track information
      const updatedSong = fbAlbum.data.tracks.find((track) => track.title === song.title);

      // Update the existing song with the fetched data
      if (updatedSong) {
        Object.assign(this.album.tracks[foundSongIndex], updatedSong);
      }

      // Step 3: Update the reference to the song within the album
      song.likes = song.likes ? song.likes : []; // Your logic to add the song to favorites
      song.likes?.push(this.userId); // Add user ID to the likes array

      // Update the reference to the album in the liked list
      const fav: likedList = {
        song: song,
        album: this.album.name,
        art: this.album.art
      };

      console.log(this.album);
      this.musicPlayer.addFav(fav, this.album);
      // Now the song within the album has been updated with new data
    }

    // Update backend or local storage accordingly
  }

  toCamelCase(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
      .join('');
  }

  // Function to remove song from favorites
  removeSongFromFav(song: any): void {
    // Your logic to remove the song from favorites
    const index = song.likes.indexOf(this.userId);
    if (index !== -1) {
      song.likes.splice(index, 1); // Remove user ID from the likes array
    }

    this.musicPlayer.removeFav(song, this.album);
  }

  toggle() {
    if (this.musicPlayer.activeTrack && this.musicPlayer.currentTrackIndex !== null) {
      this.play(this.musicPlayer.activeTrack, this.musicPlayer.currentTrackIndex);
    } else {
      this.play(this.album.tracks[0], 0);
    }
  }

  async checkDiscog(ref) {
    //checks to see if there is an album
    try {
      const all = this.musicPlayer.getDocument(ref);
      const check = await all;
      return check;
    } catch (error) {
      console.error('Error checking Discog:', error);
      throw error; // or handle the error as needed
    }
  }

  play(track, index) {
    this.musicPlayer.play(track, index);
  }

  ngOnInit() {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as { album: any };
    this.album = state.album;
    if (this.musicPlayer.activeTrack) this.selectedTrack = this.musicPlayer.activeTrack.title;

    this.musicPlayer.setAlbum(state.album);

    const date = new Date(this.album.released);
    this.album.year = date.getFullYear();
  }

  addToFav() {
    let found = false;
    if (this.globals.fav_albums.length > 0) {
      // to avoid re-entry of same album
      this.globals.fav_albums.forEach((element) => {
        if (element.name == this.album.name) {
          found = true;
        }
      });

      if (!found) {
        this.album.isFavorite = true;
        this.globals.fav_albums.push(this.album);

        this.album.tracks.forEach((element) => {
          element.isFavorite = true;
          this.globals.fav_tracks.push(element);
        });
      }
    } else {
      // Very first entry
      this.album.isFavorite = true;
      this.globals.fav_albums.push(this.album);

      this.album.tracks.forEach((element) => {
        element.isFavorite = true;
        this.globals.fav_tracks.push(element);
      });
    }

    // To remove duplicate
    this.globals.fav_tracks = this.globals.fav_tracks.reduce((unique, o) => {
      if (!unique.some((obj) => obj.name === o.name)) {
        unique.push(o);
      }
      return unique;
    }, []);

    // Saving locally
    localStorage.setItem('fav_albums', JSON.stringify(this.globals.fav_albums));
    localStorage.setItem('fav_tracks', JSON.stringify(this.globals.fav_tracks));
  }

  removeFromFav() {
    this.album.isFavorite = false;

    if (this.globals.fav_albums.length > 0) {
      this.globals.fav_albums.forEach((element, index) => {
        if (element.name == this.album.name) {
          if (index > -1) {
            this.globals.fav_albums.splice(index, 1);
          }
        }
      });

      this.album.tracks.forEach((element) => {
        element.isFavorite = false;

        this.globals.fav_tracks.forEach((fav_element, index) => {
          if (element.title == fav_element.title) {
            this.globals.fav_tracks.splice(index, 1);
          }
        });
      });

      // Saving locally
      localStorage.setItem('fav_albums', JSON.stringify(this.globals.fav_albums));
      localStorage.setItem('fav_tracks', JSON.stringify(this.globals.fav_tracks));
    }
  }

  removeTrackFromFav(track) {
    track.isFavorite = false;
    this.globals.fav_tracks.forEach((element, index) => {
      if (element.name == track.name) {
        if (index > -1) {
          this.globals.fav_tracks.splice(index, 1);
        }
      }
    });

    // Saving locally
    localStorage.setItem('fav_tracks', JSON.stringify(this.globals.fav_tracks));
  }

  addTrackToFav(track) {
    if (this.globals.fav_tracks.length == 0) {
      track.isFavorite = true;
      this.globals.fav_tracks.push(track);
    } else {
      let found = false;
      this.globals.fav_tracks.forEach((element) => {
        if (element.name == track.name) {
          found = true;
        }
      });

      if (!found) {
        track.isFavorite = true;
        this.globals.fav_tracks.push(track);
      }
    }

    // To remove duplicate
    this.globals.fav_tracks = this.globals.fav_tracks.reduce((unique, o) => {
      if (!unique.some((obj) => obj.name === o.name)) {
        unique.push(o);
      }
      return unique;
    }, []);

    // Saving locally
    localStorage.setItem('fav_tracks', JSON.stringify(this.globals.fav_tracks));
  }
}
