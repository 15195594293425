import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  messages: PushNotificationSchema[] = [];
  messagesSubject: Subject<PushNotificationSchema[]> = new Subject<PushNotificationSchema[]>();
  constructor(private router: Router) {}

  initPush() {
    Device.getInfo().then((info) => {
      if (info.platform !== 'web') this.registerPush();
    });
  }

  private registerPush() {
    PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      console.log('My token: ' + token);
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
      console.log('Push received: ' + notification);
      this.messages.push(notification); // Add the received message to the array
      this.messagesSubject.next(this.messages); // Emit the updated array
    });

    PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
      const data = notification.notification.data;
      let album = '';
      console.log('Action performed: ' + JSON.stringify(notification.notification));
      if (notification.notification.data.album) album = this.toCamelCase(notification.notification.data.album);
      if (notification.notification.data.album && data.detailsId == 'tap') {
        this.router.navigateByUrl(`/album/${album}`);
      }
    });
  }

  toCamelCase(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
      .join('');
  }
}
