import { Component, } from '@angular/core';
import { GlobalVariable } from './globals';
import { NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StorageService } from 'src/app/services/storage.service';
import { Router } from '@angular/router';
import { Share } from '@capacitor/share';
import { environment } from 'src/environments/environment';
import { getAuth, indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { Capacitor } from '@capacitor/core';
import { AuthService } from './services/auth.service';
import firebase from "firebase/compat/app";
// Required for side-effects
import { doc, getDoc, getFirestore, initializeFirestore } from "firebase/firestore";
import { MusicPlayer } from './core/musicPlayer.services';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private musicPlayer: MusicPlayer, private storage: StorageService, private auth: AuthService,
    public globals: GlobalVariable, private platform: Platform,
    public router: Router,
    public nav: NavController
  ) {

    this.initializeApp();


    // Get saved data for users
    if (localStorage.getItem("fav_albums") == '' || localStorage.getItem("fav_albums") == null || localStorage.getItem("fav_albums") == undefined) {
      this.globals.fav_albums = [];
    } else {
      this.globals.fav_albums = JSON.parse(localStorage.getItem("fav_albums"));
    }

    if (localStorage.getItem("fav_tracks") == '' || localStorage.getItem("fav_tracks") == null || localStorage.getItem("fav_tracks") == undefined) {
      this.globals.fav_tracks = [];
    } else {
      this.globals.fav_tracks = JSON.parse(localStorage.getItem("fav_tracks"));
    }


  }




  initializeApp() {



    this.platform.ready().then(async () => {
      setTimeout(() => {
        // SplashScreen.hide();
      }, 3000);


      const app = initializeApp(environment.firebase);
      // // Initialize Firebase in the constructor
      const auth = getAuth(app); // Initialize Auth module
      const db = initializeFirestore(app, { experimentalAutoDetectLongPolling: true }); // Initialize Firestore module
      // // this.data.setAuth(auth, app);

      this.auth.setFireDeets(db, auth);
    })
  }
}

